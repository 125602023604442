.content {
	display: flex;
	margin: 25px auto;
	max-width: 1500px;
	padding: 0 15px;

	.profileContent {
		flex: 2;
    padding: 0 15px;
	}

	.suggestionContent {
		flex: 1;
    padding: 0 15px;
	}
}

.background {
	height: 200px;
	background-image: url(../../images/profile-background.png);
	background-size: 100px;
	border: 5px;
  border-radius: 15px;
}

.profile {
	transform: translateY(-30px);
	padding: 0 25px;

	.profileDetails {
		.profileName {
			display: flex;
			flex-direction: column;
			font-size: larger;
			margin-bottom: 20px;
			padding: 0 10px;
		}

		.stats {
			display: flex;

			div {
				margin: 0 10px;
			}
		}

		.description {
      margin-top: 10px;
      margin-bottom: 30px;
			padding: 0 10px;
		}
	}

	.profileRow {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.profileImage {
			border: solid 2px white;
			height: 100px;
			width: 100px;
			border-radius: 50%;
			overflow: hidden;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.spacer {
			flex: 1;
		}

		button {
			padding: 0 20px;
			height: 40px;
			background-color: #1ead68;
			border: none;
			border-radius: 20px;
			color: white;
			font-size: 18px;
			cursor: pointer;
			margin-top: 30px;
			transition: background 0.2s ease, opacity 0.2s ease;

			&:hover {
				background: darken(#1ead68, 5%);
			}
		}
	}
}
