body,
html {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

*::before,
*::after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}