.navigationContainer {
  height: 60px;
}

.navigation {
  background: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  font-size: 35px;
  transform: rotate(-25deg);
  color: #1ead68;
}

.items {
  display: flex;
  height: 100%;
  flex: 1;
  padding: 0 25px;

  .spacer {
    flex: 1;
  }
}

.item {
  height: 100%;
  padding: 0 15px;
  cursor: pointer;
  color: #3c3c3c;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.item:hover {
  background: #f1f1f1;
}

.itemIcon {
  font-size: 18px;
  margin-right: 5px;
}

.profile {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
  color: #3c3c3c;
  transition: background 0.2s ease;
}

.profile:hover {
  background: #f1f1f1;
}

.profileName {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;

  b {
    font-size: 18px;
  }
}

.profileImage {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
}
