.verticalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(../../images/background-viecher.jpg);
    background-size: cover;
}

.formTextContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 9px 50px hsla(24, 26%, 4%, 0.548);
    padding: 20px;
    background-color: #ffffff;

    .contentWraperImage {
        align-self: center;
        width: auto;
        height: auto;
    
        .logo {
          font-size: 50px;
          transform: rotate(-25deg);
          color: #1ead68;
        }
    }

    .heading {
        color: #1ead68;
    }

    .text {
        font-size: 20px;
    }

    .startpageButtons {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    
        button {
          width: 220px;
          border-radius: 51px;
          margin: 5px;
          background: transparent;
          border: none;
          padding: 10px 30px;
          height: 100%;
          font-size: larger;
          color: #ffffff;
        }

        button:hover {
            cursor: pointer;
        }

        .buttonIcon {
            font-size: 20px;
            transform: rotate(-25deg);
            margin-right: 10px;
        }        
    }

    .loginButton {
        button {
            border: solid 2px #1ead68;
            background-color: #ffffff;
        }

        button,
        .buttonIcon {
            color: #1ead68;
        }
    }

    .registrationButton {
        button {
            border: solid 2px #1ead68;
            background-color: #1ead68;
        }

        button,
        .buttonIcon {
            color: #ffffff;
        }
    }

    .link {
        width: 225px;
        text-align: center;
        font-size: small;
        padding-top: 10px;
    
        a {
          color: #0000ff;
          text-decoration: none;
        }
    }
}