.content {
  display: flex;
  margin: 0 auto;
  max-width: 1500px;
}

.postContent {
  flex: 3;
}

.profileCard {
  flex: 1;
}