.post,
.singlePost,
.comment {
  color: #3c3c3c;
  border: #e2e2e2 solid;
  width: 800px;
  margin-left: 160px;
  padding: 0 50px;
  margin-top: 10px;

  .userInfo {
    margin-top: 30px;

    .profileImage {
      display: inline;
      vertical-align: middle;

      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .profileName {
      margin-left: 60px;
      display: inline;
      font-size: larger;

      a {
        margin-left: 10px;
        color: #8c8c8c;
        text-decoration: none;
        font-size: smaller;
      }

      a:hover {
        color: #3c3c3c;
      }
    }
    
    .postTime {
      margin-left: 120px;
      display: inline;
      font-size: small;
    }
  }
  
  .postMessage {
    p {
      b {
        display: block;
        margin-bottom: 20px;
        
        a {
          color: inherit;
          text-decoration: none;
        }
      }

      padding: 20px 30px;
    }

    .postImages {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: -5px 0 20px;
  
      .postImage {
        flex: 1;
        min-width: 250px;
        margin: 5px;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      &.largeImages .postImage {
        height: auto;

        img {
          height: auto;
        }
      }
    }
  }

  .commentMessage {
    p {
      padding: 10px 30px;
    }

    a {
      color: #8c8c8c;
      text-decoration: none;
    }

    a:hover {
      color: #3c3c3c;
    }

  }

  .postActions {
    text-align: center;
    margin-bottom: 20px;

    .icons {
      margin: 0 100px;
      font-size: x-large;
      color: #e2e2e2;

      &.like {
        &:hover {
          color: #ff434367;
        }

        &.active {
          color: #FF4343;
        }
      }
    }

    .icons:hover {
      color: #3c3c3c;
      cursor: pointer;
    }

    .postStatistics {
      display: inline;
      margin: 0 80px;
      font-size: large;
      color: #3c3c3c;
    }
  }
}

.singlePost {
  margin-top: 50px;
}

.comment {
  background-color: #f8f8f8;
}

