.newPost {
	flex: 3;
	display: flex;
	flex-direction: column;
	max-width: 750px;
	margin: 0 auto;
	align-items: flex-end;
	padding: 0 10px 15px;
	border-bottom: solid 1px #e2e2e2;

	.newPostRow {
		display: flex;
		align-items: flex-start;
		padding: 25px 0 15px;
		width: 100%;
	}

	.profileImage {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		object-fit: Cover;
		margin-right: 10px;
	}

	.postContent {
		flex: 1;

		textarea {
			background-color: #f0f2f5;
			border: #e2e2e2;
			width: 100%;
			min-height: 100px;
			border-radius: 10px;
			font-family: inherit;
			padding: 10px;
			resize: vertical;

			&:focus {
				outline: none;
			}
		}

		.attachements {
			height: 160px;
			display: flex;
			margin: -5px;

			.attachement {
				border-radius: 10px;
				height: 100%;
				flex: 1;
				margin: 5px;
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				padding: 5px;
        max-width: 25%;

				div {
					width: 24px;
					height: 24px;
					background: #1ead68;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					font-size: 15px;
					border-radius: 12px;
					cursor: pointer;
					transition: background 0.2s ease;

					&:hover {
						background: darken(#1ead68, 5%);
					}
				}
			}
		}
	}

	.actionRow {
		display: flex;
		align-items: center;
		width: 100%;
		padding-left: 70px;

		.imageButton {
			height: 45px;
			width: 45px;
			border-radius: 50%;
			color: #1ead68;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			cursor: pointer;
			position: relative;
			transition: background 0.2s ease;
			margin-right: 5px;

			&:hover {
				background: #1ead6838;
			}

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
			}
		}

		.gifButton {
			height: 45px;
			display: flex;
			align-items: center;
			cursor: pointer;
			position: relative;

			> span {
				background: #1ead68;
				color: white;
				font-size: 14px;
				padding: 3px 6px;
				border-radius: 3px;
				border: solid 1px #1ead68;
				transition: color 0.2s ease, background 0.2s ease;

        &:hover {
          background: transparent;
          color: #1ead68;
        }
			}

			.giphyPicker {
				position: absolute;
				top: 100%;
				left: 0;
				width: 300px;
				background: white;
        box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
        border-radius: 4px;
        padding: 6px;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease;

        &.reveal {
          opacity: 1;
          pointer-events: auto;
        }

        .header {
          border-bottom: solid 1px #e2e2e2;
          display: flex;
          align-items: center;
          height: 40px;
          margin: -6px -6px 12px;

          div {
            font-size: 13px;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background .2s ease;

            &.active, &:hover {
              background: #eeeeee;
            }
          }
        }

        .search {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          border: solid 1px #e2e2e2;
          border-radius: 5px;
          margin-bottom: 5px;

          input {
            flex: 1;
            background: none;
            border: none;
            height: 100%;
            padding: 0 15px;

            &:focus {
              outline: none;
            }
          }

          button {
            height: 100%;
            width: 40px;
            font-size: 13px;
            border-radius: 5px;
            padding: 0;
          }
        }

        .loading {
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .loading {
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #1ead68;
        }

        .results {
          overflow-y: auto;
          height: 300px;
          display: flex;
          flex-wrap: wrap;
          margin: -2px;

          img {
            flex: 1;
            min-width: 120px;
            height: 140px;
            object-fit: cover;
            object-position: center;
            margin: 2px;
            transition: opacity .2s ease;

            &:hover {
              opacity: .6;
            }
          }

          .item {
            flex: 1;
            min-width: 120px;
            height: 130px;
            margin: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #e2e2e2;
            color: #1ead68;
            font-size: 30px;
            flex-direction: column;
            cursor: pointer;
            transition: background .2s ease;

            span {
              font-size: 15px;
              margin-top: 10px;
            }

            &:hover {
              background: #f1f1f1;
            }
          }
        }
			}
		}

		.spacer {
			flex: 1;
		}

		button {
			padding: 0 35px;
			width: 140px;
			height: 45px;
			background-color: #1ead68;
			border: solid 1px #e2e2e2;
			border-radius: 51px;
			color: white;
			font-size: 20px;
			cursor: pointer;
			transition: background 0.2s ease, opacity 0.2s ease;

			&:hover {
				background: darken(#1ead68, 5%);
			}
		}

		.disabled {
			opacity: 0.8;
			pointer-events: none;
		}
	}
}

.post {
	flex: 3;
}

.pImage {
	img {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		margin-left: 200px;
		margin-top: 170px;
		object-fit: Cover;
	}
}
.profileName {
	display: flex;
	flex-direction: row;
	font-size: larger;
	padding: 0 15px;
	margin-left: 300px;
	bottom: 40px;
	position: relative;
}

.info {
	display: flex;
	flex-direction: row;
	padding: 0 15px;
	margin-left: 610px;
	top: 373px;
	position: absolute;
	font-size: small;
}

.Neu {
	p {
		margin-left: 200px;
		width: 730px;
		padding: 0 30px;
	}
}

.Image {
	img {
		height: 500px;
		width: 700px;
		border-radius: 5%;
		background-size: cover;
		background-position: center;
		margin-left: 200px;
		margin-top: 10px;
		object-fit: Cover;
		margin-bottom: 50px;
	}
}
